<template>
    <div class="Dept" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form
                @submit.native.prevent
                :inline="true"
                style="text-align: right"
                v-model="form"
                size="small"
                label-width="70px"
            >
                <el-row>
                    <el-col :span="6">
                        <el-form-item label="状态:">
                            <el-select placeholder="状态" v-model="form.noticeState">
                                <el-option label="全部" value="" />
                                <el-option label="开启" value="1" />
                                <el-option label="关闭" value="2" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="通知端:">
                            <el-select placeholder="请选择" clearable v-model="form.noticeType">
                                <el-option label="全部" value="1" />
                                <el-option label="收银机" value="2" />
                                <el-option label="后台系统" value="3" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button
                type="primary"
                @click="handleManualQuery"
                size="small"
                v-if="hasPrivilege('menu.system.dept.open')"
                >查询
            </el-button>
            <el-button
                type="primary"
                @click="handleCreate('createForm')"
                size="small"
                v-if="hasPrivilege('menu.system.dept.export')"
            >
                新建通知
            </el-button>
        </el-card>

        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                id="printMe"
                ref="table"
                border
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="500"
            >
                <el-table-column label="序号" type="index" width="60" align="center" fixed="left" />
                <el-table-column label="通知标题" width="500" prop="">
                    <template slot-scope="scope">
                        <!-- eslint-disable vue/no-v-html -->
                        <span v-html="scope.row.noticeTitle"></span>
                        <!--eslint-enable-->
                    </template>
                </el-table-column>
                <el-table-column label="是否有详情" width="100" prop="noticeDetails">
                    <template slot-scope="scope">
                        <span>{{ scope.row.noticeDetails | noticeDetails }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="面向对象" width="200" prop="noticeObject">
                    <template slot-scope="scope">
                        <span>{{ scope.row.noticeObject | noticeObject }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="状态" width="80" prop="noticeState">
                    <template slot-scope="scope">
                        <span>{{ scope.row.noticeState | noticeState }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="通知端" width="80" prop="noticeType">
                    <template slot-scope="scope">
                        <span>{{ scope.row.noticeType | noticeType }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="创建人" width="100" prop="creator" />
                <el-table-column label="创建时间" width="150" prop="createTime" sortable />
                <el-table-column label="操作" min-width="340" header-align="center" prop="operate" key="operate">
                    <template slot-scope="scope">
                        <el-button
                            size="mini"
                            type="primary"
                            :disabled="scope.row.noticeState == 1"
                            @click="handleRowUpDown(scope.row.code, 1)"
                            v-if="hasPrivilege('menu.system.dept.edit')"
                            >开启
                        </el-button>
                        <el-button
                            size="mini"
                            type="primary"
                            :disabled="scope.row.noticeState == 2"
                            @click="handleRowUpDown(scope.row.code, 2)"
                            v-if="hasPrivilege('menu.system.dept.edit')"
                            >关闭
                        </el-button>
                        <el-button
                            size="mini"
                            type="primary"
                            @click="handleRowView(scope.row)"
                            v-if="hasPrivilege('menu.system.dept.edit')"
                            >查看
                        </el-button>
                        <el-button
                            size="mini"
                            type="warning"
                            @click="handleRowEdit(scope.row)"
                            v-if="hasPrivilege('menu.system.dept.edit')"
                            >编辑
                        </el-button>
                        <el-button
                            size="mini"
                            type="danger"
                            @click="handleRowDelete(scope.row.code)"
                            v-if="hasPrivilege('menu.system.dept.delete')"
                            >删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                :page-sizes="page.pageSizes"
                :page-size="form.limit"
                :layout="page.PageStyle"
                :total="page.total"
                :current-page="form.page"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                @prev-click="handlePrevClick"
                @next-click="handleNextClick"
            />
        </el-card>
        <el-dialog
            title="新建通知:"
            :visible="dialogFormVisible"
            :before-close="handleClose"
            :destroy-on-close="false"
            class="notice-dialog"
        >
            <el-form :model="createForm" ref="createForm" :rules="rules" label-position="left">
                <span class="label">通知标题:</span>
                <editor v-model="createForm.noticeTitle"></editor>
                <span class="label">通知详情:</span>
                <detail-editor v-model="createForm.noticeDetails"></detail-editor>
                <el-form-item label="通知对象:" prop="noticeObject">
                    <el-radio-group v-model="createForm.noticeObject" :disabled="disabled || selectCashRegister">
                        <el-radio :label="1">全体用户</el-radio>
                        <el-radio :label="2">机构用户</el-radio>
                        <!--                        <el-radio :label="3">指定门店</el-radio>-->
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="通知端:" prop="noticeType">
                    <el-radio-group v-model="createForm.noticeType" :disabled="disabled" @change="noticeTypeChange">
                        <el-radio :label="1">全部</el-radio>
                        <el-radio :label="2">收银机</el-radio>
                        <el-radio :label="3">后台系统</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="自动开始通知时间:" prop="automaticStartTime">
                    <el-date-picker
                        v-model="createForm.automaticStartTime"
                        size="small"
                        type="datetime"
                        value-format="timestamp"
                        placeholder="选择日期时间"
                        :disabled="disabled"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="自动关闭通知时间:" prop="automaticEndTime">
                    <el-date-picker
                        v-model="createForm.automaticEndTime"
                        size="small"
                        type="datetime"
                        value-format="timestamp"
                        placeholder="选择日期时间"
                        :disabled="disabled"
                    >
                    </el-date-picker>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="handleClose">取 消</el-button>
                <el-button :disabled="disabled" type="primary" @click="handleRowsubmitOk('createForm')"
                    >提 交</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Util from '../../../js/Util';
import UrlUtils from '../../../js/UrlUtils';
import Editor from 'components/imcoder-tinymce';
import DetailEditor from 'components/detail-tinymce';

export default {
    name: 'Dept',
    components: {
        Editor,
        DetailEditor,
    },
    data() {
        return {
            form: {
                noticeState: '',
                noticeEvent: 1,
                page: 1,
                limit: Util.Limit,
            },
            createForm: {
                code: '',
                noticeTitle: '',
                noticeDetails: '',
                noticeState: '',
                noticeObject: '',
                automaticStartTime: '',
                automaticEndTime: '',
                noticeType: 1,
            },
            page: {
                total: 0,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
            },
            loading: false,
            dialogFormVisible: false,
            disabled: false,
            selectCashRegister: false,
            tableData: [],
            url: {
                page: '/system/notice/page',
                disable: '/system/notice/delInfoNotice',
                view: '/system/notice/info_c/',
                upDown: '/system/notice/upDownNotice',
                createExtend: '/system/notice/createExtend',
                editExtend: '/system/notice/updateInfoNotice',
            },
            rules: {
                noticeObject: [{ required: true, message: '请选择通知对象', trigger: 'change' }],
                noticeType: [{ required: true, message: '请选择通知端', trigger: 'change' }],
            },
        };
    },
    mounted() {
        this.handleQuery();
    },
    activated() {
        this.executeQueryIfNeedReload(this.handleQuery);
    },
    filters: {
        noticeDetails(noticeDetails) {
            if (noticeDetails) {
                return '是';
            } else {
                return '否';
            }
        },
        noticeObject(noticeObject) {
            if (typeof noticeObject == 'undefined') {
                return '';
            }
            if (noticeObject == 1) {
                return '全体用户';
            }
            if (noticeObject == 2) {
                return '机构用户';
            }
            if (noticeObject == 3) {
                return '指定门店';
            }
        },
        noticeState(noticeState) {
            if (typeof noticeState == 'undefined') {
                return '';
            }
            if (noticeState == 1) {
                return '开启';
            }
            if (noticeState == 2) {
                return '关闭';
            }
        },
        noticeType(noticeType) {
            if (typeof noticeType == 'undefined') {
                return '';
            }
            if (noticeType === 1) {
                return '全部';
            }
            if (noticeType === 2) {
                return '收银机';
            }
            if (noticeType === 3) {
                return '后台系统';
            }
        },
    },
    methods: {
        handleQuery() {
            const _this = this;
            const _params = {
                params: _this.form,
            };
            Util.queryTable(_this, _this.url.page, _params, (rst) => {
                rst.data.forEach((item) => {
                    if (item.noticeTitle && item.noticeEvent === 1) {
                        const a = JSON.parse(item.noticeTitle).content;
                        const b = JSON.parse(item.noticeDetails).content;
                        item.noticeTitle = a;
                        item.noticeDetails = b;
                    }
                });
                _this.tableData = rst.data;
                _this.page.total = rst.count;
            });
        },
        handleManualQuery() {
            this.form.page = 1;
            this.handleQuery();
        },
        handleCreate(formName) {
            this.submitType = 1;
            this.dialogFormVisible = true;
            setTimeout(() => {
                window.tinyMCE.get(0).setContent('<p></p>');
                window.tinyMCE.get(1).setContent('<p></p>');
            }, 500);
        },
        handleClose() {
            this.$refs['createForm'].resetFields();
            this.createForm = {
                noticeTitle: '<p></p>',
                noticeDetails: '<p></p>',
                noticeObject: '',
                noticeState: '',
                automaticStartTime: '',
                automaticEndTime: '',
                noticeType: '',
                code: '',
            };
            window.tinyMCE.get(0).getBody().setAttribute('contenteditable', true);
            window.tinyMCE.get(1).getBody().setAttribute('contenteditable', true);
            this.disabled = false;
            this.dialogFormVisible = false;
        },
        handleRowUpDown(code, flag) {
            const params = {
                params: {
                    flag: flag,
                    code: code,
                },
            };
            let message = '';
            if (flag == 1) {
                message = '开启成功';
            } else {
                message = '关闭成功';
            }
            this.$http.get(this.url.upDown, params).then((rst) => {
                if (rst.data.status === 200) {
                    this.$message.success(message);
                    this.handleQuery();
                }
            });
        },
        handleRowDelete(code) {
            const _delete = () => {
                return this.$http.delete(this.url.disable, {
                    params: {
                        code: code,
                    },
                });
            };
            UrlUtils._commonDml(this, {
                message: '是否删除该信息,删除后不可恢复,是否确认删除?',
                methods: _delete,
            });
        },
        handleRowEdit(row) {
            const _this = this;
            this.submitType = 2;
            Util.copyProperties(row, this.createForm);
            this.createForm.code = row.code;
            this.createForm.automaticEndTime = Date.parse(new Date(this.createForm.automaticEndTime)) || '';
            this.createForm.automaticStartTime = Date.parse(new Date(this.createForm.automaticStartTime)) || '';
            this.noticeTypeChange();
            _this.dialogFormVisible = true;
        },
        handleRowView(row) {
            const _this = this;
            this.disabled = true;
            Util.copyProperties(row, this.createForm);
            this.createForm.automaticEndTime = Date.parse(new Date(this.createForm.automaticEndTime));
            this.createForm.automaticStartTime = Date.parse(new Date(this.createForm.automaticStartTime));
            _this.dialogFormVisible = true;
            setTimeout(() => {
                window.tinyMCE.get(0).getBody().setAttribute('contenteditable', false);
                window.tinyMCE.get(1).getBody().setAttribute('contenteditable', false);
            }, 1000);
        },
        handleRowsubmitOk(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (window.tinyMCE.get(0).getContent().length < 7) {
                        this.$message.error('请填写通知标题');
                        return;
                    }
                    const _this = this;
                    const noticeTitle = JSON.stringify({ content: _this.createForm.noticeTitle });
                    const noticeDetails = JSON.stringify({ content: _this.createForm.noticeDetails });
                    const _params = JSON.parse(JSON.stringify(_this.createForm));
                    _params.noticeTitle = noticeTitle;
                    _params.noticeDetails = noticeDetails;
                    let url = '';
                    let message = '';
                    if (this.submitType == 1) {
                        url = _this.url.createExtend;
                        delete _params.code;
                        message = '新建成功';
                    } else {
                        url = _this.url.editExtend;
                        message = '编辑成功';
                    }
                    UrlUtils.PostRemote(
                        _this,
                        url,
                        _params,
                        null,
                        () => {
                            _this.$message.success(message);
                            _this.createForm = {
                                noticeTitle: '<p></p>',
                                noticeDetails: '<p></p>',
                                noticeObject: '',
                                noticeState: '',
                                automaticStartTime: '',
                                automaticEndTime: '',
                                noticeType: '',
                            };
                            _this.dialogFormVisible = false;
                            _this.handleQuery();
                        },
                        (fail) => {}
                    );
                } else {
                    return false;
                }
            });
        },
        noticeTypeChange() {
            if (this.createForm.noticeType === 2) {
                this.selectCashRegister = true;
                this.createForm.noticeObject = 2;
            } else {
                this.selectCashRegister = false;
            }
        },
    },
};
</script>

<style scoped>
.Dept .el-form-item {
    margin-bottom: 0;
}
.label {
    font-size: 14px;
    display: block;
    margin: 10px 0 15px;
}
iframe {
    height: 300px;
}
.notice-dialog .el-form-item {
    margin-bottom: 15px;
    margin-top: 10px;
}
</style>
